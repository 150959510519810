<template>
  <div class="container" style="padding-bottom: 72px">
    <div class="tabs-chunk">
      <div v-if="sellerType!=='个人卖家'">
        <div class="sign-title">公章设置</div>

        <div class="sign-open-msg">
          {{ enableSupplierSeal==='NO'?'开启后，电子合同上会显示上传的默认公章':'关闭后，电子合同上会隐藏上传的默认公章' }} 
          <a-popconfirm :title="enableSupplierSeal=='NO'?'确定开启':'确认关闭'" @confirm="handlerToggle()">
            <a v-if="!loadingOpen" style="margin-left:10px">{{enableSupplierSeal=='NO'?'开启':'关闭'}}</a>
            <a-spin v-else />
          </a-popconfirm>
        </div>
        
        <a-row type="flex" v-show="enableSupplierSeal=='YES'">
          <span>签署公章：</span>
          <div class="clearfix" style="min-width: 500px">
            <a-upload
              :customRequest="() => ''"
              @change="customRequest"
              accept="image/*"
              list-type="picture-card"
              :file-list="purchaserSealList"
              @preview="handlePreview"
              :remove="onSealDelete"
              :disabled="!loading"
            >
              <div v-if="purchaserSealList.length < 8 && loading" @click="type = 'default'">
                <a-row>
                  <a-icon type="plus" />
                </a-row>
                <div class="ant-upload-text">点击上传</div>
              </div>
              <a-icon v-if="!loading" style="font-size: 30px" type="loading" />
            </a-upload>
            <div class="message-text">
              <span class="tit">上传公章规格要求：</span
              >170*170PX，背景透明或白色的图片，若不上传公章，合同不会进行公章签署
            </div>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
          <!-- <a-col :span="24" class="auto-sign">
            <a-space>
              <a-checkbox :checked="isAutomaticSigning" @change="checkChange">自动签署</a-checkbox>
              <span class="hint">开启该功能时，则不发签署短信，电子合同自动签署，若签署人有上传手写签名图片则使用该签名签署，否则系统自动生成印章进行签署</span>
            </a-space>
          </a-col> -->
        </a-row>
      </div>
      <a-space class="auto-sign">
        <a-checkbox :checked="isAutomaticSigning" @change="checkChange">自动签署</a-checkbox>
        <span class="hint">开启该功能时，则不发签署短信，电子合同自动签署，若签署人有上传手写签名图片则使用该签名签署，否则系统自动生成印章进行签署</span>
      </a-space>


      <div class="sign-title">签署人管理</div>
      <a-table :data-source="signdata" :columns="signColumns" :pagination="false" rowKey="key" :loading="loadingtable"
        :row-class-name="rowClassName">
        <template v-for="col in ['signerName','signerIdCard','signerMobile']" :slot="col" slot-scope="text, record">
          <div :key="col">
            <a-input :style="{width: width(col)}" :maxLength="col | length" v-if="record.editable" :value="text"
              @change="e => handleChange(e.target.value, record.key, col)" />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="signStatus" slot-scope="text,record">
          <span v-if="record.isLegal"></span>
          <!-- <span v-else-if="text === 'UNSIGNED'" style="color: rgba(0, 0, 0, 0.25)">待签署</span>
          <span v-else-if="text === 'AUTH_SIGNED'||text === 'BE_AUTH_SIGNED'" style="color: rgba(0, 0, 0, 0.25)">部分授权</span>
          <span v-else-if="text === 'SIGNED'">已签署</span> -->

          <a-badge v-if="text === 'UNSIGNED'" status="default" text="待签署"/>
          <a-badge v-else-if="text === 'AUTH_SIGNED'||text === 'BE_AUTH_SIGNED'" status="error" text="部分授权"/>
          <a-badge v-else-if="text === 'SIGNED'" status="success" text="已签署"/>
        </template>
        <template slot="isLegal" slot-scope="text">
          <a-icon v-if="text" type="check-circle" theme="filled" class="font-error" />
        </template>
        <template slot="isDefault" slot-scope="text">
          <a-icon v-if="text == 1" type="check-circle" theme="filled" class="font-error" />
        </template>
        <template slot="signatureImgUrl" slot-scope="text, row">
          <a-upload
              v-if="!row.editable"
              :customRequest="() => ''"
              :showUploadList="false"
              accept="image/*"
              @change="customRequest($event, row.id)"
            >
            <div class="signature" :style="{backgroundImage: text && `url(${text})`}">
              <a-icon v-if="!text" type="plus" />
              <a-icon v-else type="swap" class="swap"></a-icon>
            </div>
            </a-upload>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space v-if="record.editable">
            <a-icon type="check" @click="() => save(record)"></a-icon>
            <a-icon type="close" @click="() => cancel(record.key)"></a-icon>
          </a-space>
          <a-space v-else size="large">
            <template v-if="record.isLegal">
              <a v-if="!record.isDefault" @click="switchSigner(record)">切换签署人</a>
            </template>
            <template v-else-if="record.signStatus === 'SIGNED'">
              <a v-if="record.isDefault == 1" :href="record.agrtViewUrl" target="_Blank">代签署协议</a>
              <a v-else @click="switchSigner(record)">切换签署人</a>
            </template>
            <template v-else>
              <template >
                <a-Popconfirm title="确定要重发短信？" @confirm="send(record.id)">
                  <a v-if="record.signStatus === 'UNSIGNED'||record.signStatus === 'BE_AUTH_SIGNED'">授权人<br>重发短信</a>
                </a-Popconfirm>
                <a-Popconfirm title="确定要重发短信？" @confirm="beSend(record.id)">
                  <a v-if="record.signStatus === 'UNSIGNED'||record.signStatus === 'AUTH_SIGNED'">被授权人<br>重发短信</a>
                </a-Popconfirm>
              </template>

              <!-- <a-dropdown>
                <a-menu slot="overlay"  v-if="record.signStatus === 'UNSIGNED'||record.signStatus === 'BE_AUTH_SIGNED'">
                  <a-menu-item key="1" @click="send(record.id)"> 
                    授权人重发短信 
                  </a-menu-item>
                  <a-menu-item key="2">  
                    <a :href="record.argtDocUrl" target="_Blank" style="color:#666">授权人签署链接</a>
                  </a-menu-item>
                </a-menu>
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  授权人 <a-icon type="down" />
                </a>
              </a-dropdown>
              <a-dropdown>
                <a-menu slot="overlay"  v-if="record.signStatus === 'UNSIGNED'||record.signStatus === 'AUTH_SIGNED'">
                  <a-menu-item key="1" @click="beSend(record.id)"> 被授权人重发短信 </a-menu-item>
                  <a-menu-item key="2" > 
                    <a :href="record.beAuthSignUrl" target="_Blank" style="color:#666">被授权人签署链接</a>
                   </a-menu-item>
                </a-menu>
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  被授权人 <a-icon type="down" />
                </a>
              </a-dropdown> -->
            </template>
          </a-space>
        </template>
      </a-table>
      <!-- <div class="table-foot" @click="add" v-if="!signdata.find(item => item.key === -1)">
        <a-icon type="plus"></a-icon>
      </div> -->
    </div>
    <avatar-modal :type="type" ref="modal" @getImageUrl="signatureUrl" @getSealList="getSealList()"/>
  </div>
</template>

<script>
import { signtory, addsigntory, send, swSigner, beSend, list, setdefaultseal, deleteseal, sealConfig } from '@/api/seller'
import AvatarModal from '@/views/buyercenter/components/AvatarModal'
import {signerSig,signerConfig} from '@/api/public'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const signColumns = [
  {
    title: '签署人姓名',
    dataIndex: 'signerName',
    scopedSlots: { customRender: 'signerName' }
  },
  {
    title: '身份证号',
    dataIndex: 'signerIdCard',
    scopedSlots: { customRender: 'signerIdCard' }
  },
  {
    title: '手机号',
    dataIndex: 'signerMobile',
    scopedSlots: { customRender: 'signerMobile' }
  },
  {
    title: '状态',
    dataIndex: 'signStatus',
    scopedSlots: { customRender: 'signStatus' }
  },
  {
    title: '法人',
    dataIndex: 'isLegal',
    scopedSlots: { customRender: 'isLegal' }
  },
  {
    title: '默认',
    dataIndex: 'isDefault',
    scopedSlots: { customRender: 'isDefault' }
  },
  {
    title: '签署完成时间',
    dataIndex: 'signedTime',
  },
  {
    title: '手写签名',
    dataIndex: 'signatureImgUrl',
    scopedSlots: { customRender: 'signatureImgUrl' }
  },
  // {
  //   title: '操作',
  //   scopedSlots: { customRender: 'action' }
  // },
]
const name = /^[\u4e00-\u9fa5]{2,4}$/
const phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
export default {
  props: ['id', 'name', 'individualApplyId','sellerType'],
  data() {
    this.signCacheData = []
    return {
      loadingtable: false,
      signColumns,
      signdata: [],
      editingKey: '',
      isAutomaticSigning: false,

      loadingOpen:false, // 开启和关闭公章中
      enableSupplierSeal: 'NO',
      type: 'default',
      loading:true,
      extSignNum: '', //公章数字
      previewImage: '', //公章图片
      previewVisible: false,
      purchaserSealList: [],
      defaultClass: 'ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card',
    }
  },
  components: {
    AvatarModal
  },
  mounted() {
      this.signtory()
      this.getSealList()

      // 添加印章点击事件
      document.addEventListener('click', this.clickChange)
  },
  destroyed() {
    // 删除监听印章点击事件
    // eslint-disable-next-line no-debugger
    document.removeEventListener('click', this.clickChange)
  },
  filters: {
    length(val) {
      if (!val) return ''
      const obj = {
        'signerName': 6,'signerIdCard': 18,'signerMobile': 11
      }
      return obj[val] || ''
    }
  },
  methods: {
    // 开启和关闭默认公章
    handlerToggle(){
      this.loadingOpen = true
      sealConfig({supplierId:this.$route.query.entityId,enableSupplierSeal:this.enableSupplierSeal=='NO'?'YES':'NO'}).then(res=>{
        this.loadingOpen = false
        if (res.code === 200) {
          this.enableSupplierSeal = this.enableSupplierSeal=='NO'?'YES':'NO'
        } else {
          this.$notification.error({
            message: '错误',
            description: res.message
          })
        }
      })
    },
    // 公章列表
    getSealList () {
      list({ supplierId: this.$route.query.entityId }).then(res => {
        if (res.code === 200) {
          this.purchaserSealList = res.data.map(item => {
            return {
              uid: item.id,
              name: `${item.extSignNum}`,
              status: 'done',
              url: `${item.sealUrl}`,
              isDefault: item.isDefault
            }
          })
          this.setSellNumb()
        } else {
          this.$notification.error({
            message: '错误',
            description: res.message
          })
        }
      })
    },
    // 公章操作
    setSellNumb() {
      if (document.getElementById('icon-default')) {
        document.getElementById('icon-default').remove()
      }
      const uploadItem = document.getElementsByClassName('ant-upload-list-item')
      const imgBox = document.getElementsByClassName(
        'ant-upload-list-item-info'
      )
      let chArr = document.body.getElementsByClassName("purchaser-id");
      if (chArr.length) {
        for(var i = chArr.length - 1; i >= 0; i--){
          if (chArr[i]) {
            chArr[i].parentNode.removeChild(chArr[i]); 
          }
        }
      }
      this.$nextTick(() => {
        this.isReady = false
        setTimeout(() => {
        this.purchaserSealList.forEach((item, index) => {
          var div = document.createElement('div')
          var txt = document.createTextNode(item.name)
          div.className = 'purchaser-id'
          div.appendChild(txt)
          // 如果是默认印章
          if (item.isDefault) {
            uploadItem[index].className = this.defaultClass + ' rell-defailt'
          } else {
            uploadItem[index].className = this.defaultClass
          }
          imgBox[index].setAttribute('uId', item.uid)
          uploadItem[index].appendChild(div)
        })
        this.isReady = true
        }, 310)
      })
    },
    // 设置默认公章
    clickChange(ev) {
      const that = this
      var target = ev.target || ev.srcElement
      if (target.className === 'ant-upload-list-item-info') {
        that.$confirm({
          title: '提示',
          content: '确定设置成默认公章？',
          onOk: () => {
            const uid = target.getAttribute('uId')
            setdefaultseal({
              sealId: uid,
              supplierId: that.$route.query.entityId
            }).then((res) => {
              if (res.code === 200) {
                // document.getElementById('icon-default').remove()
                that.$notification.success({
                  message: '成功',
                  description: res.message
                })
                that.getSealList()
              } else {
                that.$notification.error({
                  message: '错误',
                  description: res.message
                })
              }
            })
          },
          onCancel() {}
        })
      }
    },
    // 删除公章
    onSealDelete(file) {
      if (document.getElementById('icon-default')) {
        document.getElementById('icon-default').remove()
      }
      this.$confirm({
        title: '提示',
        content: '确定删除？',
        onOk: () => {
          deleteseal({ sealId: file.uid }).then((res) => {
            if (res.code === 200) {
              this.$notification.success({
                message: '成功',
                description: '删除成功！'
              })
              this.getSealList()
            } else {
              this.$notification.error({
                message: '错误',
                description: res.message
              })
            }
          })
        },
        onCancel() {}
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 添加时input的宽度
    width(key) {
      const obj = {
        'signerName': '80px',
        'signerIdCard': '175px',
        'signerMobile': '120px',
      }
      return obj[key]
    },
    // 获取权限管理数据
    signtory() {
      this.loadingtable = true
      signtory({ entityCharacter: 'S', entityId: this.id }).then(res => {
        this.loadingtable = false
        if (res.code === 200) {
          this.enableSupplierSeal = res.data.enableSupplierSeal
          this.signdata = res.data.signToryVoList
          this.isAutomaticSigning = res.data.automaticSigning === 'YES'
          this.signCacheData = [...this.signdata]
          this.signCacheData.forEach((item, index) => item.key = index)
        }
      })
    },
    handleChange(value, key, column) {
      const newData = [...this.signdata];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.signdata = newData;
      }
    },
    // 卖家签署人添加操作
    edit(key) {
      console.log(key)
      const newData = [...this.signdata];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.signdata = newData;
      }
    },
    add() {
      if (this.signdata.find(item => item.key === -1)) return
      const obj = {
        key: -1, isDefault: null, signStatus: null,
        signedTime: null, signerIdCard: "", signerMobile: "", signerName: ""
      }
      this.signdata.push(obj)
      this.signCacheData.push(obj)
      this.edit(obj.key)
    },
    save(record) {
      if (!this.id) {
        this.$message.error('缺少主体ID')
        return
      }
      let verify = false
      console.log(name.test(record.signerName));
      if (!name.test(record.signerName)) {
        this.$message.error('请填写正确的签属姓名')
      } else if (!(record.signerIdCard.length===18)) {
        this.$message.error('请填写正确的身份证号')
      } else if (!phone.test(record.signerMobile)) {
        this.$message.error('请填写正确的手机号')
      } else {
        verify = true
      }
      if (!verify) return
      this.loadingtable = true
      console.log('???',record)
      addsigntory({ ...record, supplierId: this.id }).then((res) => {
        this.loadingtable = false
        if (res.code === 200) {
          this.editingKey = ''
          this.$message.success(res.message)
          this.signtory()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    cancel(key) {
      const newData = [...this.signdata];
      this.signdata = newData.filter(item => key !== item.key);
      this.signCacheData = [...this.signdata]
      this.editingKey = '';
    },
    // 默认前输入颜色
    rowClassName(record) {
      return (record.isDefault === 1) ? 'check-color' : ''
    },
    beSend(signId){
      beSend({supplierId: this.id, signId}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    send(signId) {
      send({supplierId: this.id, signId}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
      
    },
    switchSigner(record) {
      swSigner({supplierId: this.id, id: record.id, individualApplyId: this.individualApplyId}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.signtory()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取签名图片url
    signatureUrl({signatureUrl, singerId}) {
      console.log({singerId,enterpriseType: 'purchaser', signatureUrl});
      signerSig({singerId,enterpriseType: 'supplier', signatureUrl}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.signtory()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async customRequest({ file }, id) {
      if (file.type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        return
      }
      console.log(file, id);
      this.$nextTick(async ()=>{
        this.$refs.modal.fileList = [file.originFileObj]
        this.$refs.modal.rowId = id
        console.log(file);
        this.$refs.modal.options.img =await this.getBase64(file.originFileObj)
         this.$refs.modal.previews.url =await this.getBase64(file.originFileObj)
         this.$refs.modal.edit(1,'sup')
      })
    },
    checkChange (e) {
      this.isAutomaticSigning = e.target.checked
      signerConfig({entityId: this.$route.query.entityId, enterpriseType: 'supplier', isAutomaticSigning: this.isAutomaticSigning}).then(res => {
        if (res.code === 200) {
          this.isAutomaticSigning = e.target.checked
          this.$message.success(res.message)
        } else {
          this.isAutomaticSigning = !e.target.checked
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .check-color {
  background: #e2e8da;
}
/deep/ .ant-col {
  margin-bottom: 12px;
}
.container {
  border-top: 1px solid #e9e9e9;
  background: #fff;
  padding: 22px 32px;
  font-size: 14px;
  .tabs-title {
    font-size: 16px;
    font-weight: 650;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
  }
}
.sign-title {
  font-size: 16px;
  font-weight: 650;
  color: rgba(0, 0, 0, 0.847058823529412);
  padding: 10px 0;
}
.tabs-chunk {
  margin-bottom: 32px;
  .table-foot {
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    user-select: none;
  }
  /deep/ .ant-table-placeholder {
    display: none;
  }
}
.add-modal {
  padding: 12px 32px;
}
.add-right {
  line-height: 32px;
  text-align: right;
}
.select-info {
  border-width: 1px;
  border-style: solid;
  border-color: #aaaaaa;
  background: #f2f2f2;
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
  /deep/ .ant-col {
    margin-bottom: 0;
  }
}
.font-error {
  color: @error;
  font-size: 16px;
}

.signature {
  width: 70px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  color: #AAAAAA;
  background-size: cover;
  cursor: pointer;
  position: relative;
  transition: all .3s;
  .swap {
    color: #fff;
    position: absolute;
    top: -5px;
    right: -5px;
  }
  &:hover {
    color: @primary;
    border: 1px solid @primary;
  }
}
.auto-sign {
  margin: 32px 0 32px 0 !important;
}
.hint {
  background-color: rgba(242, 242, 242, 1);
  color: #7F7F7F;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 5px;
  line-height: 20px;
}

.sign-open-msg{
  margin: 10px 0;
}

/deep/ .purchaser-id {
  position: absolute;
  // color: red;
  z-index: 2;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  left: 0;
}
/deep/ .rell-defailt {
  border-color: red;
  position: relative;
  &::after {
    content: '';
    height: 20px;
    width: 20px;
    background-image: url('../../../assets/images/default.png');
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
  &::before {
    content: '';
    width: 20px;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>