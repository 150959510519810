<template>
  <div class="container" style="padding-bottom: 72px">
    <div class="tabs-chunk">
      <p class="tabs-title">帐号权限管理</p>

      <a-table :data-source="roledata" :columns="roleColumns" :pagination="false" :loading="loading"
        :rowKey="(record,index)=>{return index}">
        <template slot="role" slot-scope="text,record">
          <a-badge :color="record.role === '管理员' ? 'cyan' : 'green'" :text="record.role" />
        </template>
        <template slot="action" slot-scope="text,record">
          <!-- <a-button v-if="record.role !== '管理员'" @click="edits(record)">编辑</a-button>&nbsp;&nbsp; -->
          <a-popconfirm v-if="record.role !== '管理员'" title="确认删除？" okText="确定" cancelText="取消" @confirm="() => delUserRole(record.id)">
            <a-button>删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="table-foot" @click="roleModal.off = true">
        <a-icon type="plus"></a-icon>
      </div>
    </div>
    <!-- 添加子账号 -->
    <a-modal v-model="roleModal.off" title="添加子账号" :width="600" centered :footer="null"
      @cancel="restartRole();roleModal.off=false">
      <div class="add-modal">
        <a-row type="flex">
          <a-col :span="4" class="add-right">用户账号：</a-col>
          <a-col :span="20">
            <SearchSelect ref="SearchSelect" :list="roleModal.list" @search="searchUser($event)" :focu="false" @select="roleModal.value = $event.id"/>

          </a-col>
          <a-col :span="4" class="add-right">权限：</a-col>
          <a-col :span="20">
            <a-select :value="roleModal.roleValue" style="width: 50%" @change="e => {roleModal.roleValue = e}">
              <!-- <a-select-option :value="1">
                管理员
              </a-select-option> -->
              <a-select-option :value="2">
                业务员
              </a-select-option>
            </a-select>
          </a-col>
          <a-row class="select-info" v-if="roleModal.item">
            <a-col :span="12">
              用户名：{{roleModal.item.nickName}}
            </a-col>
            <a-col :span="12">
              使用者姓名：{{roleModal.item.userName}}
            </a-col>
            <a-col :span="12">
              邮箱：
            </a-col>
            <a-col :span="12">
              手机号码：{{roleModal.item.regMobile}}
            </a-col>
          </a-row>
          <a-col :span="24" style="text-align: center;margin-top: 12px">
            <a-space :size="24">
              <a-button @click="restartRole();">重置</a-button>
              <a-button type="primary" @click="enterModal">确定</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
      <!-- 编辑子账号 -->
    <a-modal v-model="edit.off" title="编辑权限" :width="600" centered :footer="null"
      @cancel="rest();edit.off=false">
      <div class="add-modal">
        <a-row type="flex">
          <a-row class="select-info" v-if="edit.item">
            <a-col :span="12">
              用户名：{{edit.item.nickName}}
            </a-col>
            <a-col :span="12">
              使用者姓名：{{edit.item.userName}}
            </a-col>
            <a-col :span="12">
              邮箱：
            </a-col>
            <a-col :span="12">
              手机号码：{{edit.item.regMobile}}
            </a-col>
          </a-row>
          <a-col :span="4" class="add-right">权限：</a-col>
          <a-col :span="20">
            <a-select :value="edit.roleValue" style="width: 50%" @change="e => {edit.roleValue = e}" >
              <!-- <a-select-option :value="1">
                管理员
              </a-select-option> -->
              <a-select-option :value="2">
                业务员
              </a-select-option>
            </a-select>
          </a-col>
       
          <a-col :span="24" style="text-align: center;margin-top: 12px">
            <a-space :size="24">
              <a-button @click="rest();">重置</a-button>
              <a-button type="primary" @click="sur">确定</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { auth, getparty, add, deletepartycontrol } from '@/api/seller'
import SearchSelect from '@/components/SearchSelect'
import {updatepartycontrolentity} from '../../../api/buyerCenter'
import {mapGetters} from 'vuex'
import cookie from '@/utils/js-cookie.js'
const roleColumns = [
  {
    title: '子账号',
    dataIndex: 'username',
  },
  {
    title: '使用者姓名',
    dataIndex: 'name',
  },
  {
    title: '权限',
    dataIndex: 'role',
    scopedSlots: { customRender: 'role' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  },
]
const type = {
  // 1: '管理员',
  2: '业务员',
}
export default {
  props: ['id', 'name'],
  components: {
    SearchSelect
  },
  data() {
    return {
      loading: false,
      roleColumns,
      roledata: [],
      roleModal: {
        off: false,
        value: undefined,
        list: [],
        fetching: false,
        roleValue: 2,
        item: undefined
      },
          edit: {
        off: false,
        value: undefined,
        list: [],
        roleValue: 2,
        item: undefined
      },
        editinfo:{
          id:null,
      }
    }
  },
  mounted() {
      this.auth()
  },
  computed: {
    ...mapGetters(['getRoleS']),
  },
  methods: {
    // 添加时input的宽度
    width(key) {
      const obj = {
        'signerName': '80px',
        'signerIdCard': '175px',
        'signerMobile': '120px',
      }
      return obj[key]
    },
    // 获取权限管理数据
    auth() {
      this.loading = true
      auth({ entityCharacter: 'S', entityId: this.id }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.roledata = res.data
        }
      })
    },
    //   搜索
    searchUser(username) {
      if (!username) return this.$message.error('请输入用户名,姓名或者手机号模糊匹配') 
      this.roleModal.fetching = true
      getparty({ username:username,entityCharacter: 'S', entityId: this.id }).then(res => {
        this.roleModal.fetching = false
        if (res.code == 200) {
          this.roleModal.list = res.data.filter(item => {
            return item.nickName !== (this.roledata.find(i => i.username === item.nickName) || {}).username
          })
        }
      })
    },
    //   账号权限管理 a-modal选中
    searchChange(e) {
      console.log(e)
      this.roleModal.value = e
      this.roleModal.item = this.roleModal.list.find(item => item.id === this.roleModal.value)
    },
    // a-modal添加
    enterModal() {
      // const user = this.roleModal.list.find(item => item.id === this.roleModal.value)
      if (!this.id) {
        this.$message.error('缺少主体ID')
        return
      }
      if (!this.name) {
        this.$message.error('缺少供应商名称')
        return
      }
      if (!this.roleModal.value) {
        this.$message.error('缺少用户账号ID')
        return
      }
      const config = { 
        entityCharacter: 'S',
        entityId: this.id, 
        commercialName: this.name, 
        authRoleId: this.roleModal.roleValue, 
        authRoleName: type[this.roleModal.roleValue],
        partyId: this.roleModal.value
      }
      add(config).then(res => {
        if (res.code === 200) {
          this.restartRole()
          this.roleModal.off = false
          this.$message.success(res.message)
          this.auth()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // a-modal 重置
    restartRole() {
      this.roleModal = {
        off: true,
        value: undefined,
        list: [],
        fetching: false,
        roleValue: 2,
        item: undefined
      }
      this.$refs.SearchSelect.restart()
    },
    //编辑子账号
    edits(e){
        this.edit.off=true
        this.editinfo.id=e.id
        this.edit.roleValue=Number(this.findKey(e.role))
    },
    findKey (value, compare = (a, b) => a === b) {
      return Object.keys(type).find(k => compare(type[k], value))
    },
     rest(){
        this.edit={
        off: true,
        value: undefined,
        list: [],
        roleValue: 2,
        item: undefined
        }
    },
    sur(){
      const config = { 
        authRoleId:this.edit.roleValue,
        authRoleName:type[this.edit.roleValue],
        id:this.editinfo.id,
      }
      updatepartycontrolentity(config).then(async res=>{
        if (res.code===200) {
        const find = this.roledata.find(item => item.id == this.editinfo.id)
        if (find.username == cookie.get('username')) {
          await this.$store.dispatch("getuserMenu")
          const obj = {
              "SM001": '/seller-content',
              "SM002": '/seller-content/store-manage',
              "SM003": '/seller-content/order-manage',
              "SM004": '/ceshi',
          }
          const menu = this.getRoleS.find(item => item.entityId == this.$route.query.entityId) || {}
          console.log(this.getRoleS, menu);
          menu.menuListVoList.length && this.$router.replace(obj[menu.menuListVoList[0].menuCode] + (this.$route.query.entityId && `?entityId=${this.$route.query.entityId}`))
        }
        
        this.$message.success(res.message)
        this.auth()
          this.edit.off = false
        }else{
        this.$message.error(res.message)
        }
     })
    },
    // 删除子账号
    delUserRole(id) {
        deletepartycontrol({ entityCharacter: 'S', entityId: this.id, id }).then(res => {
            if (res.code === 200) {
                this.auth()
                this.$message.success(res.message)
            } else {
                this.$message.error(res.message)
            }
        })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .check-color {
  background: #e2e8da;
}
/deep/ .ant-col {
  margin-bottom: 12px;
}
.container {
  border-top: 1px solid #e9e9e9;
  background: #fff;
  padding: 22px 32px;
  font-size: 14px;
  .tabs-title {
    font-size: 16px;
    font-weight: 650;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
  }
}
.tabs-chunk {
  margin-bottom: 32px;
  .table-foot {
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    user-select: none;
  }
  /deep/ .ant-table-placeholder {
    display: none;
  }
}
.add-modal {
  padding: 12px 32px;
}
.add-right {
  line-height: 32px;
  text-align: right;
}
.select-info {
  border-width: 1px;
  border-style: solid;
  border-color: #aaaaaa;
  background: #f2f2f2;
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
  /deep/ .ant-col {
    margin-bottom: 0;
  }
}
.font-error {
  color: @error;
  font-size: 16px;
}
</style>