<template>
  <a-card>
    <a-row class="row">
      <a-col
        :span="20"
      >缴税年份：
        <a-date-picker
          mode="year"
          v-model="year"
          format="YYYY"
          :open="yearShowOne"
          @openChange="openChangeOne"
          @panelChange="onYearChange"
        />
      </a-col>
    </a-row>
    <a-tabs
      :activeKey="form.relatedQuarter"
      @change="
        form.relatedQuarter = $event
        getVoucher()
      "
    >
      <a-tab-pane :key="item.key" v-for="item in tabList" :tab="item.tab">
        <!-- <a-row>
          <a-col :span="24" class="title">本季度税款详情</a-col>
          <a-col :span="12" align="right">
            <a-button><a-icon type="upload" />当季批量下载</a-button>
          </a-col>
          <a-col :span="8">个人所得税：{{ info.totalIndIncomeTax }}</a-col>
          <a-col :span="8">城市维护建设税：{{ info.totalCmcTax }}</a-col>
          <a-col :span="8">印花税：{{ info.totalStampTax }}</a-col>

          <a-col :span="8">教育费附加：{{ info.totalEduAdd }}</a-col>
          <a-col :span="8">地方教育费附加：{{ info.totalEduLocalAdd }}</a-col>
          <a-col :span="8">水利专项收入：{{ info.totalSwccIncome }}</a-col>
        </a-row> -->
        <a-table class="pt20" bordered :columns="columns" :pagination="false" :data-source="data"> </a-table>

        <p class="hint">完税凭证文件</p>
        <a-row type="flex">
          <div class="item" v-for="it in list" :key="it.id" :class="{'item-pdf':it.type==='pdf'}">
            <a v-if="it.type==='pdf'">
              <a-icon type="file-pdf" :style="{fontSize:'40px'}" />
              <div class="file-name">{{ it.fileName | filterNamew }}</div>
            </a>
            <img :src="it.filePath" alt="完税凭证" v-else>
            <div class="mark" @click="showImg(it)">
              <a-space>
                <a-icon type="eye"/>
                <!-- <a-icon type="delete" @click.stop="voucherDel(it.id)"/> -->
              </a-space>
            </div>
          </div>
          <!-- <div class="plus" @click="add">
            <a-icon type="plus" v-if="!addLoading"/>
            <a-icon type="loading" v-else/>
          </div> -->
        </a-row>
      </a-tab-pane>
    </a-tabs>

    <!-- img -->
    <!-- <input
      v-show="false"
      id="addImage"
      ref="addImage"
      alt=""
      class="upload"
      type="file"
      name="addImage"
      multiple="multiple"
      accept="image/png,image/jpeg,image/gif,image/jpg"
      @change="getImgFile"
    /> -->
    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img alt="完税凭证" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-card>
</template>
<script>
import moment from 'moment'
// import { uploadPhoto } from '@/api/utils'
import { getVoucher } from '@/api/seller'

const columns = [
  {
    title: '扣缴时间',
    dataIndex: 'payDate'
  },
  {
    title: '核算月份',
    dataIndex: 'checkComputationTime'
  },
  {
    title: '实缴税费',
    children: [
      {
        title: '增值税',
        dataIndex: 'vatTaxAmount'
      },
      {
        title: '个人所得税',
        dataIndex: 'indIncomeTax'
      },
      {
        title: '城市维护建设税',
        dataIndex: 'cmcTax'
      },
      {
        title: '印花税',
        dataIndex: 'stampTax'
      },
      {
        title: '教育费附加',
        dataIndex: 'eduAdd'
      },
      {
        title: '地方教育费附加',
        dataIndex: 'eduLocalAdd'
      },
      {
        title: '水利专项收入',
        dataIndex: 'swccIncome'
      }
    ]
  }
]
export default {
  props: ['id'],
  data () {
    return {
      columns,
      data: [],
      year: moment(),
      yearShowOne: false,
      list: [], // 完税凭证文件
      tabList: [
        { key: 1, tab: '第一季度(1-3月)' },
        { key: 2, tab: '第二季度(4-6月)' },
        { key: 3, tab: '第三季度(7-9月)' },
        { key: 4, tab: '第四季度(10-12月)' }
      ],
      defaultActiveKey: 'first',
      addLoading: false,
      imgVisible: false,
      previewImage: '',
      info: { additionalTaxList: [] },
      form: {
        supplierId: this.id,
        relatedQuarter: 1,
        relatedYear: moment().year()
      }
    }
  },
  filters: {
    filterNamew (name) {
      return name.split('/').pop()
    }
  },
  computed: {
    monthList () {
      if (this.form.relatedQuarter === 1) {
        return [1, 2, 3]
      } else if (this.form.relatedQuarter === 2) {
        return [4, 5, 6]
      } else if (this.form.relatedQuarter === 3) {
        return [7, 8, 9]
      } else if (this.form.relatedQuarter === 4) {
        return [10, 11, 12]
      }
      return [1, 2, 3]
    }
  },
  mounted () {
    this.getVoucher()
  },
  methods: {
    getVoucher () {
      getVoucher(this.form).then((res) => {
        console.log(res)
        this.info = res.data
        this.data = this.info.additionalTaxList
        this.list = this.info.additionalTaxReceiptList.map(item => {
          // 判断数据是图片或 pdf
          if (item.fileName.split('.').pop() === 'pdf') {
            item.type = 'pdf'
          } else {
            item.type = 'img'
          }
          return item
        })
      })
    },
    openChangeOne (status) {
      // status是打开或关闭的状态
      this.yearShowOne = status
    },
    // 得到年份选择器的值
    onYearChange (value) {
      this.year = value
      this.yearShowOne = false
      this.form.relatedYear = moment(value).format('YYYY')
      // this.form.relatedQuarter = 1
      this.getVoucher()
      //  console.log(moment(value).format('YYYY'))
    },
    add () {
      if (this.addLoading) {
        return false
      }
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    // 上传img文件
    // getImgFile (e) {
    //   if (e.target.files[0].type.indexOf('image') === -1) {
    //     this.$message.error('请上传正确的格式')
    //     e.target.value = null
    //     return
    //   }
    //   this.addLoading = true
    //   uploadPhoto(Array.from(e.target.files)[0], (url, name) => {
    //     voucherAdd({ supplierId: this.$route.params.id, relatedQuarter: this.form.relatedQuarter, relatedYear: this.year.year(), fileName: name, filePath: url }).then(res => {
    //       this.addLoading = false
    //       if (res.code === 200) {
    //         this.$message.success(res.message)
    //         this.getVoucher()
    //       } else {
    //         this.$message.error(res.message)
    //       }
    //     }).catch(err => {
    //       this.addLoading = false
    //       console.log(err)
    //     })
    //   })
    //   e.target.value = null
    // },
    // 删除完税凭证 receiptId
    voucherDel (receiptId) {
      console.log(receiptId);
      // const that = this
      // this.$confirm({
      //   title: '删除完税凭证',
      //   content: '请确认是否删除',
      //   onOk () {
      //     return new Promise((resolve, reject) => {
      //       voucherDel({ receiptId }).then(res => {
      //         if (res.code === 200) {
      //           that.$message.success(res.message)
      //           that.getVoucher()
      //           resolve()
      //         } else {
      //           that.$message.error(res.message)
      //           reject(new Error())
      //         }
      //       })
      //     })
      //   }
      // })
    },
    showImg (item) {
      if (item.type === 'pdf') {
        window.open(item.filePath, '_blank')
        return
      }
      this.previewImage = item.filePath
      this.imgVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.row {
  margin-bottom: 24px;
}
.item {
  display: inline-block;
  width: 160px;
  height: 160px;
  border: 1px solid rgb(215, 215, 215);
  margin-right: 20px;
  position: relative;
  &.item-pdf{
    a{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .file-name{
      margin-top: 10px;
    }
  }
  img {
    width: 100%;
  }
  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .4);
    font-size: 18px;
    cursor: pointer;
  }
  &:hover {
    .mark {
      opacity: 1;
    }
  }
}
.plus {
  cursor: pointer;
  display: inline-block;
  width: 160px;
  height: 160px;
  line-height: 160px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(215, 215, 215);
}
.pt20 {
  padding: 20px 0;
}
.title {
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin: 20px 0 18px 0;
}
.hint {
  margin-bottom: 16px;
}

/deep/ .ant-tabs-nav-scroll {
  padding: 0 !important;
}
</style>
